export const generateMD5 = async (text) => {
  if (process.env.NODE_ENV === 'development') {
    return ''
  }
  const msgUint8 = new TextEncoder().encode(text) // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('MD5', msgUint8) // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
  return hashHex
}

export const dateFormattingToCreditCard = (dateString) => {
  const date = new Date(dateString)

  const year = date.getUTCFullYear().toString().slice(2, 4)
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')

  return month + ' / ' + year
}

// TODO FRAN MIRAR SI SE PUEDEN MERGEAR O QUE
export const stringToDateApp = (dateString) => {
  if (dateString == '') {
    return ''
  }
  let d = dateString.split('/')
  let dat = new Date(d[2] + '/' + d[1] + '/' + d[0])
  return dat
}

export const stringToDate = (date) => {
  return new Date(date)
}

export const dateToString = (date, format) => {
  if (date == '') {
    return ''
  }

  let d = new Date(date)
  let day = d.getDate()
  let month = d.getMonth() + 1
  let year = d.getFullYear()

  if (day < 10) {
    day = '0' + day
  }

  if (month < 10) {
    month = '0' + month
  }

  if (!format) {
    return year + '-' + month + '-' + day
  }

  switch (format) {
    case 'd/m/y':
      return day + '/' + month + '/' + year

    case 'y/m/d':
      return year + '-' + month + '-' + day

    case 'm/d/y':
      return month + '/' + day + '/' + year
  }
}

export const getImgCard = (cardBrand) => {
  const config = useRuntimeConfig()
  const path = `${config.public.APP_URL}/svg/payments/cards`
  switch (cardBrand.toLowerCase()) {
    case 'visa':
      return `${path}/visa.svg`

    case 'mastercard':
      return `${path}/mastercard.svg`

    case 'maestro':
      return `${path}/maestro.svg`

    default:
      return `${path}/generic.svg`
  }
}

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const capitalizeWords = (str) => {
  return str
    ? str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : ''
}

export const base64Encode = (buf, type) => {
  let string = ''
  new Uint8Array(buf).forEach((byte) => {
    string += String.fromCharCode(byte)
  })

  const base64Image = btoa(string)

  return `data:${type};base64,` + base64Image
}

export const financial = (x) => {
  const rounded = x.toFixed(2)
  return Number.parseFloat(rounded)
}

export const hexStringToArrayBuffer = (hexString) => {
  hexString = hexString.replace(/^0x/, '')

  if (hexString.length % 2 != 0) {
    return
  }

  if (hexString.match(/[G-Z\s]/i)) {
    return
  }

  return new Uint8Array(
    hexString.match(/[\dA-F]{2}/gi).map(function (s) {
      return parseInt(s, 16)
    })
  ).buffer
}

export const create_UUID = () => {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
    }
  )
  return uuid
}

export const cardDateFormattingToDate = (dateString) => {
  const formatDate = new Date(dateString + '/1')

  formatDate.setMonth(formatDate.getMonth() + 1)

  return formatDate
}

export const getISO31661NumericalCode = (countryId) => {
  switch (countryId) {
    case 240:
      return 724
    // TODO
    default:
      return 1
  }
}
export const getPriceRatioQuantity = (
  product_quantity,
  product_unit,
  criteria_unit,
  criteria_quantity,
  price
) => {
  if (product_unit.toLowerCase() != criteria_unit.toLowerCase()) {
    let quantity_converted = 0
    if (
      product_unit.toLowerCase() == 'kg' &&
      criteria_unit.toLowerCase() == 'g'
    ) {
      quantity_converted = product_quantity * 1000
    }
    if (
      product_unit.toLowerCase() == 'g' &&
      criteria_unit.toLowerCase() == 'kg'
    ) {
      quantity_converted = product_quantity / 1000
    }

    if (
      product_unit.toLowerCase() == 'l' &&
      criteria_unit.toLowerCase() == 'ml'
    ) {
      quantity_converted = product_quantity * 1000
    }

    if (
      product_unit.toLowerCase() == 'ml' &&
      criteria_unit.toLowerCase() == 'l'
    ) {
      quantity_converted = product_quantity / 1000
    }

    return formatPrice((criteria_quantity * price) / quantity_converted)
  }

  return formatPrice((criteria_quantity * price) / product_quantity)
}

export const getAvailability = (data, geozone) => {
  const CANARIAS_GEOZONE = 4
  let availability = {
    units: 0,
    eta: null,
    expiration: null,
    batch: null,
  }

  if (parseInt(geozone) == CANARIAS_GEOZONE && data) {
    const regularUnits1 = data['1']?.units ?? 0
    const regularUnits2 = data['2']?.units ?? 0
    const crossDockingUnits1 = data['1']?.units_crossdocking ?? 0
    const crossDockingUnits2 = data['2']?.units_crossdocking ?? 0
    let units =
      parseInt(regularUnits1) +
      parseInt(regularUnits2) +
      parseInt(crossDockingUnits1) +
      parseInt(crossDockingUnits2)
    const eta = data['1']?.eta ?? data['2']?.eta
    let expiration = data['1']?.expiration
    if (data['2']?.units > data['1']?.units) {
      expiration = data['2']?.expiration
    }
    availability.units = parseInt(units)
    availability.eta = eta ?? null
    availability.expiration = expiration ?? null
    availability.batch = data['1']?.batch ?? data['2']?.batch ?? null
    availability.location = data['2'].units > 0 ? 2 : 1
    availability.crossdocking_suppliers =
      data['2'].units > 0
        ? data['2'].crossdocking_suppliers
        : data['1'].crossdocking_suppliers
  } else if (data && data['1']) {
    const crossDockingUnits = data['1'].units_crossdocking ?? 0
    availability.units = parseInt(data['1'].units + crossDockingUnits)
    availability.eta = data['1'].eta ?? null
    availability.expiration = data['1'].expiration ?? null
    availability.batch = data['1'].batch ?? null
    availability.location = 1
    availability.crossdocking_suppliers = data['1'].crossdocking_suppliers ?? []
  }

  return availability
}

export const getMultibancoStartAndEndDate = () => {
  const currentDate = new Date()
  const start = new Date(currentDate)
  const end = new Date(currentDate)
  end.setDate(end.getDate() + 7)
  const startFormatted = start.toISOString()
  const endFormatted = end.toISOString()

  return { start: startFormatted, end: endFormatted }
}

export const calculateRatioNutritionFacts = (
  value,
  reference,
  basis,
  rda,
  servingSize
) => {
  let result
  switch (reference) {
    case 'day':
      result =
        basis == 2 || rda == servingSize ? value : (rda / servingSize) * value
      break
    case 'serving':
      result =
        basis == 1 || rda == servingSize ? value : (servingSize / rda) * value
      break
    default:
      result =
        basis == 3
          ? value
          : basis == 1
            ? (100 * value) / servingSize
            : (100 * value) / rda
      break
  }

  result = Math.ceil(result * 100) / 100
  return result
}

export const convertUrlToRelative = (url, locale) => {
  if (!url) return `/${locale}`
  const config = useRuntimeConfig()
  if (!url.includes('https') && !url.includes('http')) {
    url = `https://${url}`
  }
  const formatUrl = new URL(url, config.public.APP_URL)
  const host = formatUrl.host
  const allowedHosts = [
    'perfumeriassanremo.es',
    'demo.perfumeriassanremo.es',
    'www.perfumeriassanremo.es',
    'localhost:3000',
    'nutritienda.com',
    'www.nutritienda.com',
    'sanremo-staging.retuertotest.workers.dev',
  ]

  if (!allowedHosts.includes(host)) {
    return url
  }

  return url.replace(/^.*\/\/[^/]+/, '')
}

export const isAnMeasurementUnit = (unit) => {
  const mesurementUnit = ['g', 'kg', 'ml', 'l']

  return mesurementUnit.includes(unit.toLowerCase())
}

export const formatImgUrl = (image) => {
  const config = useRuntimeConfig()

  const uuidRegex = /^[0-9a-f]{8}-.*$/i

  if (uuidRegex.test(image)) {
    return `${config.CLOUDFLARE_IMAGE_DELIVERY_URL}/${config.CLOUDFLARE_IMAGE_DELIVERY_ACCOUNT_HASH}/${image}/public`
  } else {
    return `${config.CDN_IMG_SIRV_URL}/catalog/big/${image}`
  }
}

export const formatPrice = (price) => {
  if (!price) return '0.00'
  return price.toFixed(2).replace('.', ',')
}

export const buildRouteToFilter = (locale, seotag, facets) => {
  if (!facets || !locale || !seotag) return null
  const selectedFacetsData = facets.filter((facet) => {
    return facet?.values.some((value) => value.selected)
  })

  let slugsToAdd = ''

  for (const facet of selectedFacetsData) {
    const valuesSelected = facet?.values.filter((value) => value.selected)
    let slugsConcat = []
    for (const value of valuesSelected) {
      if (value.slug) slugsConcat.push(value.slug)
    }
    if (slugsConcat.length === 0) continue
    slugsToAdd += `/${slugsConcat.join(',')}`
  }
  const selectedFacetsPrice = facets.find(
    (facet) => facet.name === 'facet_price'
  )
  if (selectedFacetsPrice) {
    const valuesSelected = selectedFacetsPrice?.values.filter(
      (value) => value.selected
    )
    const values = []
    for (const value of valuesSelected) {
      values.push(value.id)
    }

    slugsToAdd += values.length > 0 ? `/${values.join(',')}` : ''
  }

  const selectedFacetsStar = facets.find(
    (facet) => facet.name === 'facet_rating'
  )

  if (selectedFacetsStar) {
    const valuesSelected = selectedFacetsStar?.values.filter(
      (value) => value.selected
    )
    const values = []
    for (const value of valuesSelected) {
      values.push(value.id)
    }

    slugsToAdd += values.length > 0 ? `/${values.join(',')}` : ''
  }

  const routeToPush = `/${locale}/${seotag}` + `${slugsToAdd}`

  return routeToPush
}

export const buildCanonicalURL = (seotags, locale, page) => {
  const config = useRuntimeConfig()
  const host = config.public.APP_URL

  return `${host}/${locale}/${seotags.join('/')}${page > 1 ? `?page=${page}` : ''}`
}

export const buildPrevNextLink = (page, totalPages, locale, seotags) => {
  const config = useRuntimeConfig()
  const host = config.public.APP_URL
  let linksPrevNext = []
  switch (page) {
    case 0:
      break

    case 2:
      if (page === totalPages) {
        linksPrevNext = [
          {
            rel: 'prev',
            href: `${host}/${locale}/${seotags.join('/')}`,
          },
        ]
        break
      }
      linksPrevNext = [
        {
          rel: 'prev',
          href: `${host}/${locale}/${seotags.join('/')}`,
        },
        {
          rel: 'next',
          href: `${host}/${locale}/${seotags.join('/')}?page=${page + 1}`,
        },
      ]
      break

    case totalPages:
      if (totalPages <= 1) {
        linksPrevNext = []
      } else {
        linksPrevNext = [
          {
            rel: 'prev',
            href: `${host}/${locale}/${seotags.join('/')}?page=${page - 1}`,
          },
        ]
      }
      break

    case 1:
      linksPrevNext = [
        {
          rel: 'next',
          href: `${host}/${locale}/${seotags.join('/')}?page=${page + 1}`,
        },
      ]
      break

    default:
      linksPrevNext = [
        {
          rel: 'prev',
          href: `${host}/${locale}/${seotags.join('/')}?page=${page - 1}`,
        },
        {
          rel: 'next',
          href: `${host}/${locale}/${seotags.join('/')}?page=${page + 1}`,
        },
      ]
      break
  }

  return linksPrevNext
}

export const OptimizeStoryBlokImage = (src) => {
  if (src && src.startsWith('https://a.storyblok.com')) return `${src}/m/`
  return src
}

export const formatOrderId = (orderId) => {
  if (!orderId || typeof orderId !== 'string') return ''
  return orderId.substring(0, 8).toUpperCase()
}

export const replaceNutritiendaText = (text) => {
  if (!text) return
  return text.replace(/nutritienda/gi, 'San Remo')
}

export function generateGradientStyle(lastFourDigits) {
  const number = parseInt(lastFourDigits, 10)

  const colorIndex = number % customColors.length

  const selectedColors = customColors[colorIndex]

  return `background: linear-gradient(135deg, ${selectedColors?.color1}, ${selectedColors?.color2});`
}

const customColors = [
  {
    name: 'Ocean Breeze',
    color1: 'hsl(160, 50%, 45%)',
    color2: 'hsl(200, 60%, 40%)',
  },
  {
    name: 'Violet Sunset',
    color1: 'hsl(240, 50%, 50%)',
    color2: 'hsl(290, 60%, 40%)',
  },
  {
    name: 'Twilight Blue',
    color1: 'hsl(200, 60%, 45%)',
    color2: 'hsl(260, 70%, 35%)',
  },
  {
    name: 'Autumn Leaves',
    color1: 'hsl(25, 85%, 55%)',
    color2: 'hsl(15, 75%, 50%)',
  },
  {
    name: 'Magenta Dream',
    color1: 'hsl(300, 60%, 50%)',
    color2: 'hsl(360, 70%, 45%)',
  },
  {
    name: 'Emerald Sea',
    color1: 'hsl(120, 40%, 50%)',
    color2: 'hsl(180, 50%, 45%)',
  },
  {
    name: 'Sunny Meadow',
    color1: 'hsl(60, 70%, 50%)',
    color2: 'hsl(90, 60%, 45%)',
  },
  {
    name: 'Deep Ocean',
    color1: 'hsl(180, 50%, 50%)',
    color2: 'hsl(220, 60%, 45%)',
  },
  {
    name: 'Forest Shade',
    color1: 'hsl(140, 45%, 55%)',
    color2: 'hsl(190, 55%, 50%)',
  },
  {
    name: 'Berry Bliss',
    color1: 'hsl(320, 65%, 50%)',
    color2: 'hsl(340, 75%, 45%)',
  },
  {
    name: 'Blue Horizon',
    color1: 'hsl(210, 55%, 50%)',
    color2: 'hsl(270, 65%, 45%)',
  },
  {
    name: 'Golden Hour',
    color1: 'hsl(50, 80%, 60%)',
    color2: 'hsl(30, 70%, 55%)',
  },
  {
    name: 'Mint Fresh',
    color1: 'hsl(110, 45%, 55%)',
    color2: 'hsl(170, 55%, 50%)',
  },
  {
    name: 'Royal Purple',
    color1: 'hsl(260, 70%, 50%)',
    color2: 'hsl(300, 80%, 45%)',
  },
  {
    name: 'Cinnamon Spice',
    color1: 'hsl(20, 85%, 55%)',
    color2: 'hsl(10, 75%, 50%)',
  },
  {
    name: 'Crimson Night',
    color1: 'hsl(340, 60%, 50%)',
    color2: 'hsl(360, 70%, 45%)',
  },
  {
    name: 'Spring Green',
    color1: 'hsl(90, 50%, 50%)',
    color2: 'hsl(130, 60%, 45%)',
  },
  {
    name: 'Indigo Sky',
    color1: 'hsl(220, 60%, 50%)',
    color2: 'hsl(280, 70%, 45%)',
  },
  {
    name: 'Golden Wheat',
    color1: 'hsl(45, 70%, 60%)',
    color2: 'hsl(65, 80%, 55%)',
  },
  {
    name: 'Jade Stream',
    color1: 'hsl(150, 55%, 50%)',
    color2: 'hsl(210, 65%, 45%)',
  },
]

/*
const customColors2 = [
  {
    name: 'Ocean Breeze',
    color1: 'hsl(160, 50%, 35%)',
    color2: 'hsl(200, 60%, 30%)',
  },
  {
    name: 'Violet Sunset',
    color1: 'hsl(240, 50%, 40%)',
    color2: 'hsl(290, 60%, 30%)',
  },
  {
    name: 'Twilight Blue',
    color1: 'hsl(200, 60%, 35%)',
    color2: 'hsl(260, 70%, 25%)',
  },
  {
    name: 'Autumn Leaves',
    color1: 'hsl(25, 85%, 45%)',
    color2: 'hsl(15, 75%, 40%)',
  },
  {
    name: 'Magenta Dream',
    color1: 'hsl(300, 60%, 40%)',
    color2: 'hsl(360, 70%, 35%)',
  },
  {
    name: 'Emerald Sea',
    color1: 'hsl(120, 40%, 40%)',
    color2: 'hsl(180, 50%, 35%)',
  },
  {
    name: 'Sunny Meadow',
    color1: 'hsl(60, 70%, 50%)',
    color2: 'hsl(90, 60%, 45%)',
  },
  {
    name: 'Deep Ocean',
    color1: 'hsl(180, 50%, 40%)',
    color2: 'hsl(220, 60%, 35%)',
  },
  {
    name: 'Forest Shade',
    color1: 'hsl(140, 45%, 45%)',
    color2: 'hsl(190, 55%, 40%)',
  },
  {
    name: 'Berry Bliss',
    color1: 'hsl(320, 65%, 40%)',
    color2: 'hsl(340, 75%, 35%)',
  },
  {
    name: 'Blue Horizon',
    color1: 'hsl(210, 55%, 40%)',
    color2: 'hsl(270, 65%, 35%)',
  },
  {
    name: 'Golden Hour',
    color1: 'hsl(50, 80%, 50%)',
    color2: 'hsl(30, 70%, 45%)',
  },
  {
    name: 'Mint Fresh',
    color1: 'hsl(110, 45%, 45%)',
    color2: 'hsl(170, 55%, 40%)',
  },
  {
    name: 'Royal Purple',
    color1: 'hsl(260, 70%, 40%)',
    color2: 'hsl(300, 80%, 35%)',
  },
  {
    name: 'Cinnamon Spice',
    color1: 'hsl(20, 85%, 45%)',
    color2: 'hsl(10, 75%, 40%)',
  },
  {
    name: 'Crimson Night',
    color1: 'hsl(340, 60%, 40%)',
    color2: 'hsl(360, 70%, 35%)',
  },
  {
    name: 'Spring Green',
    color1: 'hsl(90, 50%, 40%)',
    color2: 'hsl(130, 60%, 35%)',
  },
  {
    name: 'Indigo Sky',
    color1: 'hsl(220, 60%, 40%)',
    color2: 'hsl(280, 70%, 35%)',
  },
  {
    name: 'Golden Wheat',
    color1: 'hsl(45, 70%, 50%)',
    color2: 'hsl(65, 80%, 45%)',
  },
  {
    name: 'Jade Stream',
    color1: 'hsl(150, 55%, 40%)',
    color2: 'hsl(210, 65%, 35%)',
  },
]*/
